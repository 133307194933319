import React, { createContext, useEffect, useRef, useState } from 'react';
import { Stack, TextField, IconButton } from '@fluentui/react'
import { SendRegular, StopFilled, StopRegular , Mic16Filled, MicFilled, MicRegular} from '@fluentui/react-icons'

import { SpeechConfig, AudioConfig, SpeechRecognizer, ResultReason } from 'microsoft-cognitiveservices-speech-sdk';


import Send from '../../assets/Send.svg'
import Mic from '../../assets/Mic.svg'
import StopMic from '../../assets/StopSign.svg'


import styles from './QuestionInput.module.css'


interface Props {
  onSend: (question: string, id?: string) => void
  disabled: boolean
  placeholder?: string
  clearOnSend?: boolean
  conversationId?: string
  defaultQuestion?: string
}

export const QuestionInput = ({ defaultQuestion,onSend, disabled, placeholder, clearOnSend, conversationId }: Props) => {
  const [question, setQuestion] = useState<string>('')
  useEffect(() => {
    if(defaultQuestion){
    setQuestion(defaultQuestion || '');
    
    sendQuestion(defaultQuestion);
   } }, [defaultQuestion]
   )
  const sendQuestion = (defaultQuestion?:string) => {
     let question1 = defaultQuestion || question;
     if (disabled || isStartRecording || !question1.trim()) {
      return
    }

    if (conversationId) {
      onSend(question1, conversationId)
    } else {
      onSend(question1)
    }

    if (clearOnSend) {
      setQuestion('')
    }
  }

  const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
    if (ev.key === 'Enter' && !ev.shiftKey && !(ev.nativeEvent?.isComposing === true)) {
      ev.preventDefault()
      sendQuestion()
    }
  }

  const onQuestionChange = (_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    setQuestion(newValue || '')
  }

  

  const sendQuestionDisabled = disabled || !question.trim()
  //const [showResults, setShowResults] = React.useState(false)

// azure speech 

 const [transcribedText, setTranscribedText] = useState('');
  const [isStartRecording, setIsStartRecording] = useState(false);

const recognizerRef= useRef<any>(null);
  const startSpeechRecognition = async () => {
    const speechConfig = SpeechConfig.fromSubscription('3e1333ade487467b9dda196bb251964d', 'francecentral');
    speechConfig.speechRecognitionLanguage = 'nl-NL';
    const audioConfig: AudioConfig = AudioConfig.fromDefaultMicrophoneInput();

    const recognizer: SpeechRecognizer = new SpeechRecognizer(speechConfig, audioConfig);
    recognizerRef.current = recognizer;
    setIsStartRecording(true);
    recognizer.startContinuousRecognitionAsync();

  recognizer.recognizing = (_, { result }) => {
      if (result.reason === ResultReason.RecognizingSpeech) {
        setTranscribedText(result.text);
      }
    };

    recognizer.recognized = (_, { result }): void => {
      if (result.reason === ResultReason.RecognizedSpeech) {
        setQuestion?.(old => `${old} ${result.text}`);
        setTranscribedText(''); // Clear interim text once it's finalized

      }
    };

    recognizer.canceled = (_, cancelReason): void => {
      setIsStartRecording(false);
      console.error(cancelReason.errorDetails);
    };

    recognizer.sessionStopped = (_, sessionStoppedEvent): void => {
      setIsStartRecording(false);

      console.log(`Session stopped. SessionId: ${sessionStoppedEvent.sessionId}`);
    };
  };
const stopSpeechRecognition = () => {
    if (recognizerRef.current) {
      recognizerRef.current.stopContinuousRecognitionAsync(
        () => {
                
          setIsStartRecording(false);

          console.log('Recognition stopped.');
        },
        (err:any) => {
             
          setIsStartRecording(false);

          console.error('Error stopping recognition:', err);
        }
      );
    }
  };


  const onMicClick = () => {
  //  startSpeechRecognition();
  //  setShowResults(true);
  };
 /* const Results = () => (
             <div
            className={styles.speechButton}
            role="button"
            tabIndex={0}
            onClick={stopRecor}
          >
            <img src={Mic} alt="Mic" className={styles.micIcon} />

          </div> 
)*/
const stopRecor = () => {
   
  };
  // return (
  //   <Stack horizontal className={styles.questionInputContainer}>
  //     <TextField
  //       className={styles.questionInputTextArea}
  //       placeholder={placeholder}
  //       multiline
  //       resizable={false}
  //       borderless
  //       value={question}
  //       onChange={onQuestionChange}
  //       onKeyDown={onEnterPress}
  //     />
  //     <div
  //       className={styles.questionInputSendButtonContainer}
  //       role="button"
  //       tabIndex={0}
  //       aria-label="Ask question button"
  //       onClick={sendQuestion}
  //       onKeyDown={e => (e.key === 'Enter' || e.key === ' ' ? sendQuestion() : null)}>
  //       {sendQuestionDisabled ? (
  //         <SendRegular className={styles.questionInputSendButtonDisabled} />
  //       ) : (
  //         <img src={Send} className={styles.questionInputSendButton} alt="Send Button" />
  //       )}
  //     </div>
      

  //     <div className={styles.questionInputBottomBorder} />
  //   </Stack>
  // )

return (
    <Stack horizontal className={styles.questionInputContainer}>
      <TextField
        className={styles.questionInputTextArea}
        placeholder={placeholder}
        multiline
        resizable={false}
        borderless
        value={transcribedText? question + " " + transcribedText :question }
        onChange={onQuestionChange}
        onKeyDown={onEnterPress}
      />
      <div className={styles.questionInputSendButtonContainer}>
        
        <div className={styles.buttonsContainer}>
            {!isStartRecording? (            <div
            className={`${styles.speechButton}`}
              role="button"
              tabIndex={0}
              onClick={startSpeechRecognition}
            >
              <MicRegular className={styles.questionInputSendButtonDisabled} />
            </div>) : (    <div
            className={`${styles.speechButton} ${styles.pulse}`}
            role="button"
            tabIndex={0}
            onClick={stopSpeechRecognition}
          >

            <StopFilled className={styles.micIcon} />

          </div>) }

  
          <div
            role="button"
            tabIndex={0}
            aria-label="Ask question button"
            onClick={()=> sendQuestion()}
            onKeyDown={e => (e.key === 'Enter' || e.key === ' ' ? sendQuestion() : null)}
          >
            {sendQuestionDisabled || isStartRecording ? (
              <SendRegular className={styles.questionInputSendButtonDisabled} />
            ) : (
              <img src={Send} className={styles.questionInputSendButton} alt="Send Button" />
            )}
        </div>

          </div>

      </div>
      <div className={styles.questionInputBottomBorder} />
    </Stack>
  )

}
